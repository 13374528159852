import React from "react";
import Profile from "./sections/account/profile";

export default function Account() {
  return (
    <div>
      <Profile />
    </div>
    
  );
}