import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from "../store";

  export interface User {
    "id": String,
    "email": String,
  }

  export interface UserResponse {
    user: User
    accessToken: string
  }
  
  export interface LoginRequest {
    email: string
    password: string
  }

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({
      baseUrl: "/",
      prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = (getState() as RootState).auth.accessToken
        console.log(`Token: ${token}`)
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
        return headers
      },
    }),
    endpoints: (builder) => ({
        users:builder.query<User[], void>({
            query:() => 'http://localhost:3006/'
        }),
        login: builder.mutation<UserResponse, LoginRequest>({
          query: (credentials) => ({
            url: 'http://localhost:3006/login',
            method: 'POST',
            body: credentials,
          }),
          
        }),
        protected: builder.mutation<{ message: string }, void>({
          query: () => 'protected',
        }),
    })
})

// export const { useUsersQuery } = authApi;
export const { useLoginMutation, useProtectedMutation } = authApi