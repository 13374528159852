import { BrowserRouter, Route, Switch } from "react-router-dom";
import Account from "./pages/Account";
import Avatar from "./pages/sections/account/avatar";
import Login from "./pages/sections/global/login";
import Founders from "./pages/Founders";
import "./App.css";
import "./assets/main.css";
import Home from "./pages/Home";

function App() {
  return (
    <div className="min-h-screen bg-white">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route
            path="/account/profile"
            exact
            component={Account}
          />
          <Route
            path="/account/profile/avatar"
            exact
            component={Avatar}
          />
          <Route
            path="/user/login"
            exact
            component={Login}
          />
          <Route
            path="/founders"
            exact
            component={Founders}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
