import React from "react";
// import { Menu } from '@headlessui/react'

export default function TeamInfoSection() {
  // const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              Meet our leadership
            </h2>
            <p className="text-xl text-gray-500">
              We all have different strengths and passions that complement each
              other and help enable our success.
            </p>
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
              <li>
                <div className="flex items-center space-x-4 lg:space-x-6">
                  <img
                    className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=lqyVugAZB6&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <div className="font-medium text-lg leading-6 space-y-1">
                    <h3>Aubrey</h3>
                    <p className="text-indigo-600">Co-Founder</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex items-center space-x-4 lg:space-x-6">
                  <img
                    className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                    src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixqx=lqyVugAZB6&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <div className="font-medium text-lg leading-6 space-y-1">
                    <h3>Dan</h3>
                    <p className="text-indigo-600">Co-Founder</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="flex items-center space-x-4 lg:space-x-6">
                  <img
                    className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                    src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixqx=lqyVugAZB6&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <div className="font-medium text-lg leading-6 space-y-1">
                    <h3>Dave</h3>
                    <p className="text-indigo-600">Co-Founder</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
