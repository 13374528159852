import React from "react";
import MainMenu from "./global/menu";
import BlogMain from "./hero/BlogMain";
import LegendsFeature from "./LegendsFeature";
import TeamInfo from "./hero/TeamInfo";
import Footer from "./global/Footer";
import { ChevronRightIcon } from '@heroicons/react/solid'
// import { ProtectedComponent } from "../../features/protectedComponent";
// import { useAuth } from "../../services/hooks/useAuth";

import Typewriter from 'typewriter-effect';


export default function HomeMainSection() {

  // const {data, error, isFetching, isLoading, isSuccess} = useUsersQuery();
  // const { user } = useAuth()

  return (
    <div className="bg-white">
      <MainMenu />

      <main>
        <div className="pt-10 bg-white sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-24">
                  <a
                    href="#main"
                    className="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                  >
                    <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-indigo-500 rounded-full">
                      People focused
                    </span>
                    <span className="ml-4 text-sm">Read our Mission</span>
                    <ChevronRightIcon className="ml-2 w-5 h-5 text-gray-500" aria-hidden="true" />
                  </a>
                  <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-grey-500 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="block">A smarter way to</span>
                    <span className="block text-indigo-400">
                      <Typewriter
                      options={{
                        strings: ['Communicate', 'Build Relationships', 'Gain Insights', 'Build Communities'],
                        autoStart: true,
                        loop: true,
                      }}
                      />
                    </span>
                  </h1>
                  <p className="mt-3 text-base text-gray-900 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Happy Labs are focused on continiously and positivley creating and nudging behaviours in people.
                    {/* {isLoading && <h2>...Loading</h2>}
                    {isFetching && <h2>...Fetching</h2>}
                    {error && <h2>It's Broken... FIX IT!</h2>}
                    {isSuccess && (
                      <div>
                        {data.map(user => {
                          return <div className="data" key={user.id}>
                            <span>
                              {user.fullName}
                            </span>
                            </div>
                        })}
                      </div>
                    )} */}
                  </p>
                  <div className="mt-10 sm:mt-12">
                    <form action="#" className="sm:max-w-xl sm:mx-auto lg:mx-0">
                      <div className="sm:flex">
                        <div className="min-w-0 flex-1">
                          <label htmlFor="email" className="sr-only">
                            Email address
                          </label>
                          <input
                            id="email"
                            type="email"
                            placeholder="Enter your email"
                            className="block min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-indigo-500 placeholder-gray-500 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                          />
                        </div>
                        <div className="mt-3 sm:mt-0 sm:ml-3">
                          <button
                            type="submit"
                            className="block w-full py-2 px-4 rounded-md shadow bg-indigo-500 text-white font-medium hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
                          >
                            Register Interest
                          </button>
                        </div>
                      </div>
                      <p className="mt-3 text-sm text-gray-400 sm:mt-4">
                        To register your interest in joining our Happy Community then share your details and we can start{' '}
                        <a href="#main" className="font-medium text-white">
                          supporting your people together
                        </a>
                        .
                      </p>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                  <img
                    className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="/happy-labs.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* More main page content here... */}

        {/* {user ? (
            <>
              <h1>{user.email}</h1>
            </>
          ) : 
            <>
              Error: <pre>FIX IT</pre>
            </>
          }
        <ProtectedComponent /> */}
        
        
        <LegendsFeature />
        <BlogMain />
        <TeamInfo />
        <Footer />
      </main>

    </div>
  )
}
