import React from "react";
import MainMenu from "../global/menu";
import {
  ArrowNarrowLeftIcon,
  HomeIcon,
  ThumbUpIcon,
  UserIcon,
} from '@heroicons/react/solid'

const breadcrumbs = [
  { name: 'Account', href: '#', current: false },
  { name: 'Profile', href: '#', current: false },
]

const eventTypes = {
  available: { icon: UserIcon, bgColorClass: 'bg-blue-500 hover:bg-blue-700' },
  claimed: { icon: ThumbUpIcon, bgColorClass: 'bg-green-500 hover:bg-green-700' },
}

const rewards = [
  {
    id: 1,
    type: eventTypes.claimed,
    source: 'Video Wall awarded you',
    reward_item: '1 Free Startbucks Coffee',
    status: 'Claimed',
  },
  {
    id: 2,
    type: eventTypes.available,
    source: 'Happy Feedback awarded you',
    reward_item: '1 Free Startbucks Coffee',
    status: 'Available',
  },
  {
    id: 3,
    type: eventTypes.claimed,
    source: 'Happy Feedback awarded you',
    reward_item: '£20 Deliveroo Credit',
    status: 'Claimed',
  },
  {
    id: 4,
    type: eventTypes.available,
    source: 'Video Wall awarded you',
    reward_item: 'Family Day Pass to Merlin Attractions',
    status: 'Available',
  },
  {
    id: 5,
    type: eventTypes.available,
    source: 'Happy Feedback awarded you',
    reward_item: '1 Free Lunch In The Office',
    status: 'Available',
  },
]

const comments = [
  {
    id: 1,
    name: 'David McThomas',
    date: '1d ago',
    imageId: '1494790108377-be9c29b29330',
    situation: 'Ducimus quas delectus ad maxime totam doloribus reiciendis ex. Tempore dolorem maiores. Similique voluptatibus tempore non ut.',
    impact: 'It really hurt me to hear you say that',
    suggestion: 'I would love for you to talk to me first next time',
  },
  {
    id: 2,
    name: 'David McThomas',
    date: '6d ago',
    imageId: '1519244703995-f4e0f30006d5',
    situation: 'Ducimus quas delectus ad maxime totam doloribus reiciendis ex. Tempore dolorem maiores. Similique voluptatibus tempore non ut.',
    impact: 'I was so happy to see you show up in this way.',
    suggestion: 'I would love to find out more and help you with this',
  },
  {
    id: 3,
    name: 'David McThomas',
    date: '40d ago',
    imageId: '1506794778202-cad84cf45f1d',
    situation: 'Ducimus quas delectus ad maxime totam doloribus reiciendis ex. Tempore dolorem maiores. Similique voluptatibus tempore non ut.',
    impact: 'It made me feel quite anxious and not wanting to offer my opinion during the call',
    suggestion: 'It would be great if you could try and undersatnd others perspectives more and be open to new ideas',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">

      <MainMenu />

        {/* Breadcrumbs */}
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="border-t border-gray-200 py-3">
              <nav className="flex" aria-label="Breadcrumb">
                <div className="flex sm:hidden">
                  <a
                    href="#profile"
                    className="group inline-flex space-x-3 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    <ArrowNarrowLeftIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-600"
                      aria-hidden="true"
                    />
                    <span>Back to Applicants</span>
                  </a>
                </div>
                <div className="hidden sm:block">
                  <ol className="flex items-center space-x-4">
                    <li>
                      <div>
                        <a href="/" className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li>
                    {breadcrumbs.map((item) => (
                      <li key={item.name}>
                        <div className="flex items-center">
                          <svg
                            className="flex-shrink-0 h-5 w-5 text-gray-300"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                          </svg>
                          <a
                            href={item.href}
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
              </nav>
            </div>
          </div>
        </header>

        <main className="py-10">
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src="/me.png"
                    alt=""
                  />
                  <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                </div>
                <div className="text-center">
                    <a href="/account/profile/avatar" className="text-gray-900 text-sm font-medium underline">
                        Update
                    </a>
                </div>
              </div>

              <div>
                <h1 className="text-2xl font-bold text-gray-900">Dave McThomas</h1>
                <p className="text-sm font-medium text-gray-500">
                  A wandering{' '}
                  <a href="#profile" className="text-gray-900">
                    Agile Coach
                  </a>{' '}
                  last seen on <time dateTime="2020-08-25">August 25, 2020</time>
                </p>
              </div>
            </div>

          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                      Little Known Facts About Me (LiKFAM)
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">The stuff you may or may not know.</p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">My passions are...</dt>
                        <dd className="mt-1 text-sm text-gray-900">Family, Coaching, Innovation</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">I'm interested in...</dt>
                        <dd className="mt-1 text-sm text-gray-900">Gaming, Cooking, Sci-Fi, DC/Marvel, Collectibles</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">I have always wanted to...</dt>
                        <dd className="mt-1 text-sm text-gray-900">Learn how to fly</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">You can find me in...</dt>
                        <dd className="mt-1 text-sm text-gray-900">The Motor Business, Within the U92 Team</dd>
                      </div>
                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">My journey so far...</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat.
                          Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia
                          proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                        </dd>
                      </div>
                      
                    </dl>
                  </div>
                </div>
              </section>

              {/* Comments*/}
              <section aria-labelledby="notes-title">
                <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                  <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6">
                      <h2 id="my_feedback-title" className="text-lg font-medium text-gray-900">
                        My Feedback
                      </h2>
                    </div>
                    <div className="px-4 py-6 sm:px-6">
                      <ul className="space-y-8">
                        {comments.map((comment) => (
                          <li key={comment.id}>
                            <div className="flex space-x-3">
                              <div className="flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={`/me.png`}
                                  alt=""
                                />
                              </div>
                              <div>
                                <div className="text-sm">
                                  <a href="#profile" className="font-medium text-gray-900">
                                    {comment.name}
                                  </a>
                                </div>
                                <div className="mt-1 text-sm text-gray-700">
                                    <h2 id="situation-title" className="text-md font-bold text-gray-900">Situation:</h2>
                                    <p>{comment.situation}</p>
                                    <h2 id="situation-title" className="text-md font-bold text-gray-900">Impact:</h2>
                                    <p>{comment.impact}</p>
                                    <h2 id="situation-title" className="text-md font-bold text-gray-900">Suggestion:</h2>
                                    <p>{comment.suggestion}</p>
                                </div>
                                <div className="mt-2 text-sm space-x-2">
                                  <span className="text-gray-500 font-medium">{comment.date}</span>{' '}
                                  <span className="text-gray-500 font-medium">&middot;</span>{' '}
                                  <button type="button" className="text-gray-900 font-medium">
                                    Reply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                 </div>
              </section>
            </div>

            <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                  My Rewards
                </h2>

                {/* Rewards Feed */}
                <div className="mt-6 flow-root">
                  <ul className="-mb-8">
                    {rewards.map((item, itemIdx) => (
                      <li key={item.id}>
                        <div className="relative pb-8">
                          {itemIdx !== rewards.length - 1 ? (
                            <span
                              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex space-x-3">
                            <div>
                              <span
                                className={classNames(
                                  item.type.bgColorClass,
                                  'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                )}
                              >
                                <item.type.icon className="w-5 h-5 text-white" aria-hidden="true" />
                              </span>
                            </div>
                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                              <div>
                                <p className="text-sm text-gray-500">
                                  {item.source}{' '}
                                  <a href="#profile" className="font-medium text-gray-900">
                                    {item.reward_item}
                                  </a>
                                </p>
                              </div>
                              <div className="text-right text-sm whitespace-nowrap text-gray-500">
                              <button
                                    type="button"
                                    className={classNames(
                                        item.type.bgColorClass,
                                        'inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                                      )}                                      
                                >
                                    {item.status}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  )
}