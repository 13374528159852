import React, { useState } from "react";
import Avatar, { genConfig } from "react-nice-avatar";
import MainMenu from "../global/menu";
import { saveAs } from "file-saver";
import domtoimage from "dom-to-image";
import {
    ArrowNarrowLeftIcon,
    HomeIcon,
    DownloadIcon,
  } from '@heroicons/react/solid'

export default function NiceAvatar() {
  const [config, setConfig] = useState(
    genConfig({
      isGradient: true,
      hairColorRandom: false
    })
  );

  const avatarId = "myAvatar";
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumbs = [
    { name: 'Account', href: '#', current: false },
    { name: 'Profile', href: '#', current: false },
    { name: 'Avatar', href: '#', current: false },
  ]

  const colors = [
    "White",
    "Orange",
    "Wheat",
    "Peachpuff",
    "Lightgreen",
    "Lightblue",
    "Beige",
    "Cadetblue",
    "Brown",
    "Crimson",
    "Lightpink",
    "Violet"
  ];

  function updateConfig(key, value) {
    const savedConfig = config;
    savedConfig[key] = value;
    setConfig({ ...savedConfig });
  }

  const shape = ["circle", "rounded", "square"];
  const sex = ["man", "woman"];
  const faceColor = colors;
  const earSize = ["small", "big"];
  const hairColor = colors;
  const hairStyle = ["normal", "thick", "mohawk", "womanLong", "womanShort"];
  const hatColor = colors;
  const hatStyle = ["none", "beanie", "turban"];
  const eyeStyle = ["circle", "oval", "smile"];
  const glassesStyle = ["none", "round", "square"];
  const noseStyle = ["short", "long", "round"];
  const mouthStyle = ["laugh", "smile", "peace"];
  const shirtStyle = ["hoody", "short", "polo"];
  const shirtColor = colors;
  const bgColor = colors;

  const configs = {
    shape,
    sex,
    faceColor,
    earSize,
    hairColor,
    hairStyle,
    hatColor,
    hatStyle,
    eyeStyle,
    glassesStyle,
    noseStyle,
    mouthStyle,
    shirtStyle,
    shirtColor,
    bgColor
  };

  function formatText(string) {
    if (string.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/)) {
      return string;
    } else {
      return string.split(/(?=[A-Z])/).join(" ");
    }
  }

  const download = async () => {
    setIsLoading(true);
    const scale = 2;
    const node = document.getElementById(avatarId);
    if (node) {
      const blob = await domtoimage.toBlob(node, {
        height: node.offsetHeight * scale,
        style: {
          transform: `scale(${scale}) translate(${
            node.offsetWidth / 2 / scale
          }px, ${node.offsetHeight / 2 / scale}px)`,
          "border-radius": 0
        },
        width: node.offsetWidth * scale
      });
      saveAs(blob, "avatar.png");
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-full">
        
        <MainMenu />
        
        {/* Breadcrumbs */}
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="border-t border-gray-200 py-3">
              <nav className="flex" aria-label="Breadcrumb">
                <div className="flex sm:hidden">
                  <a
                    href="#profile"
                    className="group inline-flex space-x-3 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    <ArrowNarrowLeftIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-600"
                      aria-hidden="true"
                    />
                    <span>Back to Applicants</span>
                  </a>
                </div>
                <div className="hidden sm:block">
                  <ol className="flex items-center space-x-4">
                    <li>
                      <div>
                        <a href="/" className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li>
                    {breadcrumbs.map((item) => (
                      <li key={item.name}>
                        <div className="flex items-center">
                          <svg
                            className="flex-shrink-0 h-5 w-5 text-gray-300"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                          </svg>
                          <a
                            href={item.href}
                            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
              </nav>
            </div>
          </div>
        </header>
          

        <main className="py-10">

            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

            <div className="space-y-6 lg:col-start-1 lg:col-span-2"> 
                
                    
            
                        {Object.keys(configs).map((el, i) => (

                        <section aria-labelledby="applicant-information-title">
                            <span className="text-sm font-medium text-gray-500 uppercase"
                                        >
                                        {formatText(el)}
                            </span>
                            <div className="bg-white shadow sm:rounded-lg py-2">
                                <div key={i}>
                                    
                                    <div spacing={5}>
                                        {configs[el].map((name, id) => (
                                            
                                            <button
                                            key={id}
                                            type="button"
                                            className={name === config[el] 
                                                ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700 mx-1 my-1 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-md text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" 
                                                : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50 mx-1 my-1 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-md text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"}
                                            
                                            
                                            /* NEED TO DEBUG THIS AS IT SHOULD HAVE WORKED AND IS CLEANER THAN EXAMPLE ABOVE
                                            className={(name === config[el] 
                                            ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700" 
                                            : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                            'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1'
                                            )} */

                                            onClick={() => updateConfig(el, name)}
                                            >                                            
                                            {formatText(name)}
                                            </button>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </section>
                        ))}

                        {/* <span as={"pre"} fontSize={"xs"}>
                            {JSON.stringify(config, undefined, 2)}
                        </span> */}
            
            
                    
                
            </div>   
        
        
          <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 ustify-items-center">
            
              <Avatar
              style={{
                width: "16rem",
                height: "16rem",
                border: "10px solid #3f3f46"
              }}
              id={avatarId}
              {...config}
            />
            <button
              className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => download()}
            >
            <DownloadIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            {isLoading}
              Download
            </button>

            </div>
            </section>

            </div>

            </main>
        
          </div>
  );
}