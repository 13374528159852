import React from "react";
// import { Menu } from '@headlessui/react'

export default function BlogMainSection() {
  // const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center"></div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover"
                src="https://images.unsplash.com/photo-1584013979505-67ba6e45cfda?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1506&q=80"
                alt=""
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-sm font-medium text-indigo-600">
                  <a href="#top" className="hover:underline">
                    Video
                  </a>
                </p>
                <a href="#top" className="block mt-2">
                  <p className="text-xl font-semibold text-gray-900">
                    Happy Labs Innovation Approach
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    In this video we explore and share how we go about rapid
                    innovation of new product ideas at Happy Labs. Sharing how
                    'Get it in a customers hand!' remains our mantra throughout.
                  </p>
                </a>
              </div>
              <div className="mt-6 flex items-center">
                <div className="flex-shrink-0">
                  <a href="#top">
                    <span className="sr-only">Aubrey Stearn</span>
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixqx=lqyVugAZB6&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </a>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">
                    <a href="#top" className="hover:underline">
                      Aubrey Stearn
                    </a>
                  </p>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime="2020-03-16">Mar 05, 2021</time>
                    <span aria-hidden="true">·</span>
                    <span>10 min watch</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover"
                alt=""
                src="https://images.unsplash.com/photo-1537444532052-2afbf769b76c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80"
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-sm font-medium text-indigo-600">
                  <a href="#top" className="hover:underline">
                    Article
                  </a>
                </p>
                <a href="#top" className="block mt-2">
                  <p className="text-xl font-semibold text-gray-900">
                    Motivation is the Key to innovations success!
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    In this arcticle we explore how motivation can be the make
                    or break of a innovation lab/effort inside a company, as
                    well as sharing several tips to help motivate people.
                  </p>
                </a>
              </div>
              <div className="mt-6 flex items-center">
                <div className="flex-shrink-0">
                  <a href="#top">
                    <span className="sr-only">David McThomas</span>
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=lqyVugAZB6&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </a>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">
                    <a href="#top" className="hover:underline">
                      David McThomas
                    </a>
                  </p>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime="2020-03-10">Mar 10, 2021</time>
                    <span aria-hidden="true">·</span>
                    <span>8 min read</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover"
                alt=""
                src="https://images.unsplash.com/photo-1611931960487-4932667079f1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1534&q=80"
              />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-sm font-medium text-indigo-600">
                  <a href="#top" className="hover:underline">
                    Case Study
                  </a>
                </p>
                <a href="#top" className="block mt-2">
                  <p className="text-xl font-semibold text-gray-900">
                    Our next innovation will help envoke change in people
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    Here we look at how change occurs within companies and what
                    are the things that can disable and enable effective and
                    sustainable change to occur. With a sneak preview on our
                    next change enabaling innovation.
                  </p>
                </a>
              </div>
              <div className="mt-6 flex items-center">
                <div className="flex-shrink-0">
                  <a href="#top">
                    <span className="sr-only">Aubrey and Dave</span>
                    <img
                      className="h-10 w-10 rounded-full"
                      alt=""
                      src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixqx=lqyVugAZB6&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    />
                  </a>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">
                    <a href="#top" className="hover:underline">
                      Aubrey and Dave
                    </a>
                  </p>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime="2020-02-12">Apr 12, 2021</time>
                    <span aria-hidden="true">·</span>
                    <span>11 min read</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
