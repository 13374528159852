import React from "react";
import HeroMain from "./sections/HeroMain";

export default function Home() {
  return (
    <div>
      <HeroMain />
    </div>
    
  );
}