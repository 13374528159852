
/* const Menu = [
  { name: 'Our Mission', href: '#' },
  { name: 'Happy Labs', href: '#' },
  { name: 'Our Story', href: '#' },
] */

const Menu = {
  categories: [
    {
      name: 'Happy Labs',
      featured: [
        {
          name: 'Video Wall',
          href: '#',
          imageSrc: '/video_wall.jpeg',
          imageAlt: 'Hats and sweaters on wood shelves next to various colors of t-shirts on hangers.',
        },
        {
          name: 'Happiness Heartbeat',
          href: '#',
          imageSrc: '/heartbeat.png',
          imageAlt: 'Model wearing light heather gray t-shirt.',
        },
        {
          name: 'Happy Town',
          href: '#',
          imageSrc: '/game.jpeg',
          imageAlt:
            'Grey 6-panel baseball hat with black brim, black mountain graphic on front, and light heather gray body.',
        },
        {
          name: 'Happy Cards',
          href: '#',
          imageSrc: '/card_game.jpeg',
          imageAlt: 'Model putting folded cash into slim card holder olive leather wallet with hand stitching.',
        },
      ],
    },
  ],
  pages: [
    { name: 'Our Mission', href: '#' },
    { name: 'Our Story', href: '#' },
    { name: 'The Founders', href: '/founders' },
  ],
}


export default Menu;
